import {
  ANNOUNCE_STATUS,
  COLLECT_KOL_STATUS,
  EVENT_STATUS_OPTIONS,
  EVENT_STATUS_OPTIONS_2,
  EXTRA_TYPE,
  GROUP_PUSH,
  NOTIFICATION_DEVICE,
  NOTIFICATION_STATUS,
  OS_OPTIONS,
  PLATFORM,
  POST_SHOW_STATUS,
  POST_TYPE,
  PURCHASE_METHOD_OPTIONS,
  PURCHASE_STATUS_OPTIONS,
  RANKING_SETTING_STATUS_OPTIONS,
  REDIREACT_TYPE,
  REQUEST_STATUS,
  USER_TYPE,
} from "app/constants";
import { getOptionsCountry } from "app/utils";

const QUERIES = {
  USERS_LIST: "users-list",
  VIDEOS_REPORT: "videos-report",
  CONTENTS_REPORT: "contents-report",
  MEMBERS_REPORT: "members-report",
  USERS_SUMMARY: "users-summary",
  ARTICLE_SUMMARY: "article-summary",
  KEY_WORKS: "key-works",
  APP_DOWNLOADS_REPORT: "app-downloads-report",
  VIDEO_VIEWS_REPORT: "video-views-report",
  MULTI_USER_ACTIVE_REPORT: "multi-user-active-report",
  USERS_RANKING: "users-ranking",
  TOP_CONTENT_REACTION: "top-content-reaction",
  REALTIME_USER_ACTIVE_REPORT: "realtime-user-active-report",
  LIKE_AND_COMMENT_REPORT: "like-and-comment-report",
  LIST_NOTIFICATION: "list-notification",
  CREATE_NOTIFICATION: "create-notification",
  LIST_CS: "list-cs",
  REDIRECT_SCREEN: "redirect-screen",
};
const dataImgRanking = [
  "/media/ranking/1.svg",
  "/media/ranking/2.svg",
  "/media/ranking/3.svg",
  "/media/ranking/4.svg",
  "/media/ranking/5.svg",
];

const optionsWeek: any = (intl: any) => [
  { label: intl.formatMessage({ id: "SUNDAY" }) as string, value: "0" },
  { label: intl.formatMessage({ id: "MONDAY" }) as string, value: "1" },
  { label: intl.formatMessage({ id: "TUESDAY" }) as string, value: "2" },
  { label: intl.formatMessage({ id: "WEDNESDAY" }) as string, value: "3" },
  { label: intl.formatMessage({ id: "THURSDAY" }) as string, value: "4" },
  { label: intl.formatMessage({ id: "FRIDAY" }) as string, value: "5" },
  { label: intl.formatMessage({ id: "SATURDAY" }) as string, value: "6" },
];

const optionsMonth: any = (intl: any) => [
  { label: intl.formatMessage({ id: "FIRST-DAY" }), value: "1" },
];

const OPTION_KEYS_HEADER = {
  USER_ACTIVE: [
    { id: "RESET", label: "" },
    { id: "LIMIT", label: "" },
    // {
    //   id: "SELECT-OPTION",
    //   label: "ACCOUNT-TYPE",
    //   dataOptions: ACCOUNT_TYPE,
    //   type_filter_key: "type",
    // },
    { id: "SEARCH", placeholder: "SEARCH.NAME-EMAIL" },
    { id: "DATE-RANGE", label: "CREATED-AT" },
    {
      id: "MULTI-OPTIONS",
      title: "PLATFORM",
      type: "platform",
      dataOptions: PLATFORM,
    },
    {
      id: "SELECT-OPTION",
      label: "USER_TYPE",
      type_filter_key: "type",
      dataOptions: USER_TYPE,
    },
    // {
    //   id: "SELECT-OPTION",
    //   label: "OS",
    //   dataOptions: OS_OPTIONS,
    //   type_filter_key: "device_os",
    // },
  ],
  USER_DELETED: [
    { id: "RESET", label: "" },
    { id: "LIMIT", label: "" },
    { id: "SEARCH", placeholder: "SEARCH.NAME-EMAIL" },
    { id: "DATE-RANGE", label: "CREATED-AT" },
    {
      id: "MULTI-OPTIONS",
      title: "PLATFORM",
      type: "platform",
      dataOptions: PLATFORM,
    },
    {
      id: "SELECT-OPTION",
      label: "OS",
      dataOptions: OS_OPTIONS,
      type_filter_key: "device_os",
    },
  ],
  POSTS: [
    { id: "RESET", label: "" },
    { id: "LIMIT", label: "" },
    {
      id: "SELECT-OPTION",
      label: "DISPLAY-STATUS",
      dataOptions: POST_SHOW_STATUS,
      type_filter_key: "is_deleted",
    },
    { id: "SEARCH", placeholder: "TYPE.DETAILS" },
    {
      id: "SEARCH",
      placeholder: "SEARCH.NAME-EMAIL",
      searchType: "search_user",
    },
    { id: "DATE-RANGE", label: "CREATED-AT" },

    {
      id: "MULTI-OPTIONS",
      title: "ARTICLE_TYPE",
      type: "type",
      dataOptions: POST_TYPE,
    },
  ],
  QUIZ: [
    { id: "RESET", label: "" },
    { id: "LIMIT", label: "" },
    {
      id: "SELECT-OPTION",
      label: "DISPLAY-STATUS",
      dataOptions: POST_SHOW_STATUS,
      type_filter_key: "is_deleted",
    },
    { id: "SEARCH", placeholder: "TYPE.DETAILS" },
    {
      id: "SEARCH",
      placeholder: "SEARCH.NAME-EMAIL",
      searchType: "search_user",
    },
    { id: "DATE-RANGE", label: "CREATED-AT" },
  ],
  ANNOUNCE: [
    { id: "RESET", label: "" },
    { id: "LIMIT", label: "" },
    {
      id: "SELECT-OPTION",
      label: "DISPLAY-STATUS",
      dataOptions: ANNOUNCE_STATUS,
      type_filter_key: "status",
    },
    { id: "SEARCH", placeholder: "TYPE.DETAILS" },
    { id: "DATE-RANGE", label: "CREATED-AT" },
  ],
  POSTS_CRAWL: [
    { id: "RESET", label: "" },
    { id: "LIMIT", label: "" },
    {
      id: "SELECT-OPTION",
      label: "STATUS",
      dataOptions: COLLECT_KOL_STATUS,
      type_filter_key: "status",
    },
    { id: "SEARCH", placeholder: "SEARCH.NAME-EMAIL", searchType: "search" },
    {
      id: "SEARCH",
      placeholder: "SEARCH.YOUTUBE",
      searchType: "search_youtube",
    },
    {
      id: "SEARCH",
      placeholder: "SEARCH.INSTAGRAM",
      searchType: "search_instagram",
    },
  ],
  HASHTAG: [
    { id: "RESET", label: "" },
    { id: "LIMIT", label: "" },
    { id: "SEARCH", placeholder: "RANKING-SETTING.PLACEHOLDER" },
    { id: "DATE-RANGE", label: "CREATED-AT" },
  ],
  VIDEOS: [
    { id: "RESET", label: "" },
    { id: "LIMIT", label: "" },
    { id: "SEARCH", placeholder: "VIDEO-SEARCH" },
    { id: "DATE-RANGE", label: "CREATED-AT" },
  ],
  SHARES: [
    { id: "RESET", label: "" },
    { id: "LIMIT", label: "" },
    { id: "SEARCH", placeholder: "TYPE.DETAILS" },
    { id: "DATE-RANGE", label: "CREATED-AT" },
  ],
  RANKING_ALL: [
    { id: "RESET", label: "" },
    { id: "LIMIT", label: "" },
    { id: "SEARCH", placeholder: "RANKING.PLACEHOLDER" },
    { id: "DATE-RANGE", label: "CREATED-AT" },
  ],
  RANKING_WEEKLY: [
    { id: "RESET", label: "" },
    { id: "LIMIT", label: "" },
    { id: "SEARCH", placeholder: "RANKING.PLACEHOLDER" },
    { id: "DATE-RANGE-RANKING-WEEKLY" },
  ],
  RANKING_DAILY: [
    { id: "RESET", label: "" },
    { id: "LIMIT", label: "" },
    { id: "SEARCH", placeholder: "RANKING.PLACEHOLDER" },
    { id: "DATE-RANGE-RANKING-DAILY" },
  ],
  RANKING_MONTHLY: [
    { id: "RESET", label: "" },
    { id: "LIMIT", label: "" },
    { id: "SEARCH", placeholder: "RANKING.PLACEHOLDER" },
    { id: "DATE-RANGE-RANKING-MONTHLY" },
  ],
  EVENTS: [
    { id: "RESET", label: "" },
    { id: "LIMIT", label: "" },
    { id: "SEARCH", placeholder: "NOTIFY.PLACEHOLDER.SEARCH" },
    {
      id: "SELECT-OPTION",
      label: "STATUS",
      dataOptions: EVENT_STATUS_OPTIONS,
      type_filter_key: "status",
    },
    {
      id: "SELECT-OPTION",
      label: "EVENT-STATUS",
      dataOptions: EVENT_STATUS_OPTIONS_2,
      type_filter_key: "event_status",
    },
  ],
  NOTIFICATIONS: [
    { id: "RESET", label: "" },
    { id: "LIMIT", label: "" },
    // {
    //   id: "SELECT-OPTION",
    //   label: "NOTIFY-TYPE",
    //   dataOptions: NOTIFY_TYPE,
    //   type_filter_key: "group",
    // },
    { id: "SEARCH", placeholder: "NOTIFY.PLACEHOLDER.SEARCH" },
    {
      id: "MULTI-OPTIONS",
      title: "DEVICES",
      type: "device_type",
      dataOptions: NOTIFICATION_DEVICE,
    },
    {
      id: "MULTI-OPTIONS",
      title: "PUSH-STATUS",
      type: "push_status",
      dataOptions: NOTIFICATION_STATUS,
    },
    {
      id: "MULTI-OPTIONS",
      title: "REDIRECT_TYPE",
      type: "redirect_type",
      dataOptions: REDIREACT_TYPE,
    },
    {
      id: "SELECT-OPTION",
      label: "NOTIFY-TYPE",
      type_filter_key: "group",
      dataOptions: GROUP_PUSH,
    },
  ],

  ADMIN: [
    { id: "RESET", label: "" },
    { id: "LIMIT", label: "" },
    { id: "SEARCH", placeholder: "SEARCH-EMAIL" },
  ],
  ROLE: [
    { id: "RESET", label: "" },
    { id: "LIMIT", label: "" },
    { id: "SEARCH", placeholder: "SEARCH-ROLE-NAME" },
  ],
  USER_LOG: [
    { id: "RESET", label: "" },
    { id: "LIMIT", label: "" },
    { id: "SEARCH", placeholder: "SEARCH-IP-PATH-NICKNAME" },
    { id: "DATE-RANGE", label: "CREATED-AT" },
  ],
  ADMIN_LOG: [
    { id: "RESET", label: "" },
    { id: "LIMIT", label: "" },
    { id: "SEARCH", placeholder: "RANKING.PLACEHOLDER" },
    { id: "DATE-RANGE", label: "CREATED-AT" },
  ],
  RANKING_SETTING: [
    { id: "RESET", label: "" },
    { id: "LIMIT", label: "" },
    { id: "SEARCH", placeholder: "RANKING-SETTING.PLACEHOLDER" },
    {
      id: "SELECT-OPTION",
      label: "STATUS",
      dataOptions: RANKING_SETTING_STATUS_OPTIONS,
      type_filter_key: "status",
    },
  ],
  NOTIFICATION_LOG: [
    { id: "RESET", label: "" },
    { id: "LIMIT", label: "" },
    { id: "SEARCH", placeholder: "RANKING.PLACEHOLDER" },
    { id: "DATE-RANGE", label: "CREATED-AT" },
  ],
  PURCHASE_HISTORY: [
    { id: "RESET", label: "" },
    { id: "LIMIT", label: "" },
    { id: "SEARCH", placeholder: "SEARCH.NAME-EMAIL" },
    { id: "DATE-RANGE", label: "CREATED-AT" },
    {
      id: "MULTI-OPTIONS",
      title: "EXTRA-TYPE",
      type: "extra_type",
      dataOptions: EXTRA_TYPE,
    },
  ],
  WITHDRAW: [
    { id: "RESET", label: "" },
    { id: "LIMIT", label: "" },
    { id: "SEARCH", placeholder: "SEARCH.USER-AMOUNT-REQUESTCODE" },
    { id: "DATE-RANGE", label: "CREATED-AT" },
    {
      id: "MULTI-OPTIONS",
      title: "REQUEST_STATUS",
      type: "request_status",
      dataOptions: REQUEST_STATUS,
    },
  ],
  PRODUCT: [
    { id: "RESET", label: "" },
    { id: "LIMIT", label: "" },
    { id: "SEARCH", placeholder: "SEARCH.NAME" },
    { id: "DATE-RANGE", label: "CREATED-AT" },
  ],
  CATEGORIES: [
    { id: "RESET", label: "" },
    { id: "LIMIT", label: "" },
    { id: "SEARCH", placeholder: "SEARCH.NAME" },
  ],

  SIMULATION: [
    { id: "LIMIT", label: "" },
    { id: "SEARCH", placeholder: "SEARCH_ID_CONTENT" },
    { id: "DATE-RANGE", label: "CREATED-AT" },
    {
      id: "SMALL-NUMBER-INPUT",
      label: "EXPOSURE_RANGE",
      placeholder: "FROM",
      type_filter_key: "min_view_count",
    },
    {
      id: "SMALL-NUMBER-INPUT",
      placeholder: "TO",
      type_filter_key: "max_view_count",
    },
  ],
  DATE_RANGE: [{ id: "DATE-RANGE", label: "CREATED-AT" }],
  PURCHASE_STATUS: [
    { id: "RESET", label: "" },
    { id: "LIMIT", label: "" },
    {
      id: "SEARCH",
      placeholder: "SEARCH.NAME-EMAIL",
      searchType: "keyword",
    },
    { id: "DATE-RANGE", label: "CREATED-AT" },
    {
      id: "SELECT-OPTION",
      label: "COUNTRY",
      dataOptions: getOptionsCountry(),
      type_filter_key: "phone_code",
    },
    {
      id: "SELECT-OPTION",
      label: "PURCHASE_METHOD",
      dataOptions: PURCHASE_METHOD_OPTIONS,
      type_filter_key: "method",
    },
    {
      id: "SELECT-OPTION",
      label: "PURCHASE_STATUS",
      dataOptions: PURCHASE_STATUS_OPTIONS,
      type_filter_key: "status",
      width: 200,
    },
  ],
};

const PERMISSIONS_MAP = {
  VIEW_DASHBOARD: "view-dashboard",
  USER_SUMMARY: "user-summary",
  CREATE_USER: "create-user",
  UPDATE_USER: "update-user",
  REMOVE_USER: "remove-user",
  LIST_USER: "list-user",
  LIST_USER_DELETED: "list-deleted-user",
  CREATE_ADMIN: "create-admin",
  UPDATE_ADMIN: "update-admin",
  REMOVE_ADMIN: "remove-admin",
  LIST_ADMIN: "list-admin",
  CREATE_ROLE: "create-role",
  UPDATE_ROLE: "update-role",
  REMOVE_ROLE: "remove-role",
  LIST_PRODUCT: "list-product",
  CREATE_PRODUCT: "create-product",
  UPDATE_PRODUCT: "update-product",
  REMOVE_PRODUCT: "remove-product",
  LIST_WITHDRAW: "list-withdraw",
  UPDATE_WITHDRAW: "list-product",
  LIST_PURCHASE_HISTORY: "list-purchase-history",
  LIST_ROLE: "list-role",
  CONTENT_SUMMARY: "content-summary",
  CREATE_VIDEO: "create-video",
  UPDATE_VIDEO: "update-video",
  REMOVE_VIDEO: "remove-video",
  LIST_VIDEO: "list-video",
  DELETE_POST: "delete-post",
  RESTORE_POST: "restore-post",
  LIST_POST: "list-post",
  LIST_SHARE: "list-share",
  LIST_HASHTAG: "list-hashtag",
  REMOVE_HASHTAG: "remove-hashtag",
  LIST_RANKING: "list-ranking",
  CREATE_PUSH: "create-push",
  UPDATE_PUSH: "update-push",
  REMOVE_PUSH: "remove-push",
  LIST_PUSH: "list-push",
  CREATE_EVENT: "create-event",
  UPDATE_EVENT: "update-event",
  REMOVE_EVENT: "remove-event",
  LIST_EVENT: "list-event",
  CREATE_NOTICE: "create-notice",
  UPDATE_NOTICE: "update-notice",
  REMOVE_NOTICE: "remove-notice",
  LIST_NOTICE: "list-notice",
  REPLY_CS: "reply-cs",
  LIST_CS: "list-cs",
  UPDATE_RANKING_SETTING: "update-ranking-setting",
  LIST_RANKING_SETTING: "list-ranking-setting",
  UPDATE_GENERAL_SETTING: "update-general-setting",
  LIST_REPORT: "list-report",
  CREATE_REPORT: "create-report",
  UPDATE_REPORT: "update-report",
  REMOVE_REPORT: "remove-report",
  LIST_APP_SETTING: "list-app-setting",
  UPDATE_APP_SETTING: "update-app-setting",
  CREATE_APP_SETTING: "create-app-setting",
  REMOVE_APP_SETTING: "remove-app-setting",
  LIST_APP_CONTENT: "list-app-content",
  UPDATE_APP_CONTENT: "update-app-content",
  CREATE_APP_CONTENT: "create-app-content",
  REMOVE_APP_CONTENT: "remove-app-content",
  LIST_SETTING: "list-config",
  LIST_TERM: "list-term",
  UPDATE_TERM: "update-term",
  UPDATE_POLICY: "update-policy",
  UPDATE_AD_SETTING: "update-ad-setting",
  LIST_LOCALIZATION_SETTING: "list-localization",
  UPDATE_LOCALIZATION_SETTING: "update-localization",
  LIST_ADMIN_LOG: "list-admin-log",
  LIST_USER_LOG: "list-user-log",
  NOTIFICATION_LOG: "list-logs",
  UPDATE_CONFIG: "update-config",
  LIST_CATEGORIES: "list-category",
  CREATE_CATEGORIES: "create-category",
  UPDATE_CATEGORIES: "update-category",
  REMOVE_CATEGORIES: "remove-category",
  VIEW_LIST_KOL_CONTENT: "list-kol-content",
  CREATE_KOL_CONTENT: "create-kol-content",
  UPDATE_KOL_CONTENT: "update-kol-content",
  DISPLAY_CONFIGURATION: "display-configuration",
  UPDATE_DISPLAY_CONFIGURATION: "update-display-configuration",
  DISPLAY_SIMULATION: "display-simulation",
  VIEW_CONTENT_LIST: "viewed-content-list",
  FEED_SETTING: "feed-setting",
  UPDATE_FEED_SETTING: "update-feed-setting",
  FEED_DISPLAY: "feed-display",
  AUDITION_DASHBOARD: "dashboard",
  AUDITION_PURCHASE_STATUS: "purchase-status",
};
const hasPermission = (
  permissionName: any,
  myPermission: any,
  allPermission: any
) => {
  const userPermission = myPermission.find(
    (perm: any) => perm.name === permissionName
  );
  const allPermissionArray = allPermission.find(
    (perm: any) => perm.name === permissionName
  );

  return (
    userPermission &&
    allPermissionArray &&
    userPermission.id === allPermissionArray.id
  );
};

export function subString(str: string, len: number) {
  if (!str) return;
  if (str.length <= len) return str;
  return str.substring(0, len) + "...";
}

export function isEnableTooltip(str: string, len: number) {
  if (!str) return;
  if (str.length <= len) return false;
  return true;
}

const dataMappingRouteAndPermission = [
  { key: "USER_SUMMARY", value: "/user-management/summary" },
  { key: "LIST_USER", value: "/user-management/users/active" },
  { key: "LIST_USER_DELETED", value: "/user-management/users/deleted" },
  { key: "LIST_USER_LOG", value: "/user-management/users-log" },
  { key: "LIST_ADMIN", value: "/admin-management/admin" },
  { key: "LIST_ROLE", value: "/admin-management/role" },
  { key: "LIST_ADMIN_LOG", value: "/admin-management/log-history" },
  { key: "CONTENT_SUMMARY", value: "/content/article-summary" },
  { key: "LIST_VIDEO", value: "/content/video" },
  { key: "LIST_POST", value: "/content/article-management" },
  { key: "LIST_SHARE", value: "/content/share-management" },
  { key: "LIST_HASHTAG", value: "/content/key-works" },
  { key: "LIST_RANKING", value: "/ranking" },
  { key: "LIST_PUSH", value: "/alarm-management/push-management" },
  { key: "LIST_EVENT", value: "/alarm-management/event" },
  { key: "LIST_NOTICE", value: "/alarm-management/notice-management" },
  { key: "LIST_CS", value: "/cs" },
  { key: "LIST_RANKING_SETTING", value: "/setting-management/rank-setting" },
  { key: "LIST_SETTING", value: "/setting-management/ad-setting" },
  { key: "LIST_APP_SETTING", value: "/setting-management/app-setting" },
  { key: "LIST_TERM", value: "/setting-management/term-setting" },
  {
    key: "LIST_LOCALIZATION",
    value: "/setting-management/localization-setting",
  },
];

const REWARD_TYPE = {
  PIC: 0,
  PRIZE: 1,
};

const QUIZ_STATUS = {
  COMING: 0,
  RUNNING: 1,
  FINISHED: 1,
};

const QUIZ_TOTAL = {
  PARTICIPANT: 0,
  CORRECT: 1,
  WINNER: 2,
};

const SHIPPING_STATUS = {
  PREPARE: 0,
  SENDING: 1,
  DELIVERED: 2,
  CANCEL: 3,
};

export {
  QUERIES,
  dataImgRanking,
  optionsWeek,
  optionsMonth,
  OPTION_KEYS_HEADER,
  PERMISSIONS_MAP,
  hasPermission,
  dataMappingRouteAndPermission,
  REWARD_TYPE,
  QUIZ_STATUS,
  QUIZ_TOTAL,
  SHIPPING_STATUS,
};
