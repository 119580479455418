/* eslint-disable react/jsx-no-target-blank */
import { useAuth } from "app/_modules/auth";
import { useIntl } from "react-intl";
import { PERMISSIONS_MAP } from "../../../../helpers";
import { SidebarMenuItem } from "./SidebarMenuItem";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";

const SidebarMenuMain = () => {
  const intl = useIntl();

  const { myPermission, allPermission } = useAuth();

  const hasPermission = (permissionName: any) => {
    const userPermission = myPermission.find(
      (perm: any) => perm.name === permissionName
    );

    const allPermissionArray = allPermission.find(
      (perm: any) => perm.name === permissionName
    );

    return (
      userPermission &&
      allPermissionArray &&
      // eslint-disable-next-line eqeqeq
      userPermission.id == allPermissionArray.id
    );
  };

  const canViewUserManagement =
    hasPermission(PERMISSIONS_MAP.USER_SUMMARY) ||
    hasPermission(PERMISSIONS_MAP.LIST_USER) ||
    hasPermission(PERMISSIONS_MAP.LIST_USER_DELETED) ||
    hasPermission(PERMISSIONS_MAP.LIST_USER_LOG);

  const canViewAdminManagement =
    hasPermission(PERMISSIONS_MAP.LIST_ADMIN) ||
    hasPermission(PERMISSIONS_MAP.LIST_ROLE) ||
    hasPermission(PERMISSIONS_MAP.LIST_ADMIN_LOG);

  const canViewContentManagement =
    hasPermission(PERMISSIONS_MAP.CONTENT_SUMMARY) ||
    hasPermission(PERMISSIONS_MAP.LIST_VIDEO) ||
    hasPermission(PERMISSIONS_MAP.LIST_POST) ||
    hasPermission(PERMISSIONS_MAP.LIST_SHARE) ||
    hasPermission(PERMISSIONS_MAP.LIST_HASHTAG);

  const canViewKOLContentManagement = hasPermission(
    PERMISSIONS_MAP.VIEW_LIST_KOL_CONTENT
  );

  const canViewProductManagement =
    hasPermission(PERMISSIONS_MAP.LIST_PRODUCT) ||
    hasPermission(PERMISSIONS_MAP.CREATE_PRODUCT) ||
    hasPermission(PERMISSIONS_MAP.UPDATE_PRODUCT) ||
    hasPermission(PERMISSIONS_MAP.REMOVE_PRODUCT);
  const canViewCategoriesManagement =
    hasPermission(PERMISSIONS_MAP.LIST_CATEGORIES) ||
    hasPermission(PERMISSIONS_MAP.CREATE_CATEGORIES) ||
    hasPermission(PERMISSIONS_MAP.UPDATE_CATEGORIES) ||
    hasPermission(PERMISSIONS_MAP.REMOVE_CATEGORIES);
  const canViewWithdrawRequest =
    hasPermission(PERMISSIONS_MAP.LIST_WITHDRAW) ||
    hasPermission(PERMISSIONS_MAP.UPDATE_WITHDRAW);

  const canViewPurchaseHistoryRequest = hasPermission(
    PERMISSIONS_MAP.LIST_PURCHASE_HISTORY
  );

  const canViewAlarmManagement =
    hasPermission(PERMISSIONS_MAP.LIST_PUSH) ||
    hasPermission(PERMISSIONS_MAP.LIST_EVENT) ||
    hasPermission(PERMISSIONS_MAP.NOTIFICATION_LOG) ||
    hasPermission(PERMISSIONS_MAP.LIST_NOTICE);

  const canViewSettingManagement = hasPermission(PERMISSIONS_MAP.LIST_SETTING);

  const canViewFeedDisplaySimulationManagement =
    hasPermission(PERMISSIONS_MAP.UPDATE_DISPLAY_CONFIGURATION) ||
    hasPermission(PERMISSIONS_MAP.DISPLAY_CONFIGURATION) ||
    hasPermission(PERMISSIONS_MAP.DISPLAY_SIMULATION) ||
    hasPermission(PERMISSIONS_MAP.VIEW_CONTENT_LIST);

  const canViewFeedDisplaySettingManagement =
    hasPermission(PERMISSIONS_MAP.FEED_SETTING) ||
    hasPermission(PERMISSIONS_MAP.UPDATE_FEED_SETTING) ||
    hasPermission(PERMISSIONS_MAP.FEED_DISPLAY);

  const canViewAuditionEventManagement =
    hasPermission(PERMISSIONS_MAP.AUDITION_DASHBOARD) ||
    hasPermission(PERMISSIONS_MAP.AUDITION_PURCHASE_STATUS);

  return (
    <>
      {hasPermission(PERMISSIONS_MAP.VIEW_DASHBOARD) && (
        <SidebarMenuItem
          to="/dashboard"
          icon="element-11"
          title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
          fontIcon="bi-app-indicator"
        />
      )}

      {canViewUserManagement && (
        <SidebarMenuItemWithSub
          to="/user-management"
          icon="profile-circle"
          title={intl.formatMessage({ id: "MENU.USER-MANAGEMENT" })}
          fontIcon="bi-layers"
        >
          {hasPermission(PERMISSIONS_MAP.USER_SUMMARY) && (
            <SidebarMenuItem
              to="/user-management/summary"
              title={intl.formatMessage({
                id: "MENU.USER-MANAGEMENT.SUMMARY",
              })}
              hasBullet={true}
            />
          )}

          {hasPermission(PERMISSIONS_MAP.LIST_USER) && (
            <SidebarMenuItem
              to="/user-management/users/active"
              title={intl.formatMessage({
                id: "MENU.USER-MANAGEMENT.ACTIVE-USERS",
              })}
              hasBullet={true}
            />
          )}
          {hasPermission(PERMISSIONS_MAP.LIST_USER_DELETED) && (
            <SidebarMenuItem
              to="/user-management/users/deleted"
              title={intl.formatMessage({
                id: "MENU.USER-MANAGEMENT.DELETED-ACCOUNTS",
              })}
              hasBullet={true}
            />
          )}
          {hasPermission(PERMISSIONS_MAP.LIST_USER_LOG) && (
            <SidebarMenuItem
              to="/user-management/user-log"
              title={intl.formatMessage({
                id: "MENU.USER-MANAGEMENT.USER-LOG",
              })}
              hasBullet={true}
            />
          )}
        </SidebarMenuItemWithSub>
      )}
      {canViewAdminManagement && (
        <SidebarMenuItemWithSub
          to="/admin-management"
          title={intl.formatMessage({ id: "ADMIN-MANAGEMENT" })}
          fontIcon="bi-chat-left"
          icon="user-edit"
        >
          {hasPermission(PERMISSIONS_MAP.LIST_ADMIN) && (
            <SidebarMenuItem
              to="/admin-management/admin"
              title={intl.formatMessage({ id: "ADMIN" })}
              hasBullet={true}
            />
          )}
          {hasPermission(PERMISSIONS_MAP.LIST_ROLE) && (
            <SidebarMenuItem
              to="/admin-management/role"
              title={intl.formatMessage({ id: "ROLES" })}
              hasBullet={true}
            />
          )}
          {hasPermission(PERMISSIONS_MAP.LIST_ADMIN_LOG) && (
            <SidebarMenuItem
              to="/admin-management/log-history"
              title={intl.formatMessage({ id: "ADMIN-LOG" })}
              hasBullet={true}
            />
          )}
        </SidebarMenuItemWithSub>
      )}

      {canViewContentManagement && (
        <SidebarMenuItemWithSub
          to="/content"
          icon="delivery-3"
          title={intl.formatMessage({
            id: "MENU.CONTENT-MANAGEMENT",
          })}
          fontIcon="bi-layers"
        >
          {hasPermission(PERMISSIONS_MAP.CONTENT_SUMMARY) && (
            <SidebarMenuItem
              to="content/article-summary"
              title={intl.formatMessage({
                id: "MENU.ARTICLE-MANAGEMENT.SUMMARY",
              })}
              hasBullet={true}
            />
          )}
          {hasPermission(PERMISSIONS_MAP.LIST_VIDEO) && (
            <SidebarMenuItem
              to="content/video"
              title={intl.formatMessage({
                id: "MENU.VIDEO-MANAGEMENT",
              })}
              hasBullet={true}
            />
          )}
          {hasPermission(PERMISSIONS_MAP.LIST_POST) && (
            <SidebarMenuItem
              to="content/article-management"
              title={intl.formatMessage({
                id: "MENU.ARTICLE-MANAGEMENT",
              })}
              hasBullet={true}
            />
          )}
          {hasPermission(PERMISSIONS_MAP.LIST_POST) && (
            <SidebarMenuItem
              to="content/quiz-management"
              title={intl.formatMessage({
                id: "MENU.QUIZ-MANAGEMENT",
              })}
              hasBullet={true}
            />
          )}
          {hasPermission(PERMISSIONS_MAP.LIST_POST) && (
            <SidebarMenuItem
              to="content/announce-management"
              title={intl.formatMessage({
                id: "MENU.ANNOUNCE-MANAGEMENT",
              })}
              hasBullet={true}
            />
          )}

          {hasPermission(PERMISSIONS_MAP.LIST_SHARE) && (
            <SidebarMenuItem
              to="content/share-management"
              title={intl.formatMessage({
                id: "MENU.SHARE-MANAGEMENT",
              })}
              hasBullet={true}
            />
          )}

          {hasPermission(PERMISSIONS_MAP.LIST_HASHTAG) && (
            <SidebarMenuItem
              to="content/key-works"
              title={intl.formatMessage({
                id: "MENU.HASHTAG",
              })}
              hasBullet={true}
            />
          )}
        </SidebarMenuItemWithSub>
      )}

      {canViewKOLContentManagement && (
        <SidebarMenuItemWithSub
          to="/crawl"
          icon="abstract-26"
          title={intl.formatMessage({
            id: "MENU.CONTENT-CRAWL-MANAGEMENT",
          })}
          fontIcon="bi-layers"
        >
          {hasPermission(PERMISSIONS_MAP.LIST_POST) && (
            <SidebarMenuItem
              to="/crawl/article-management"
              title={intl.formatMessage({
                id: "MENU.CRAWL-ARTICLE-MANAGEMENT",
              })}
              hasBullet={true}
            />
          )}
        </SidebarMenuItemWithSub>
      )}

      {canViewProductManagement && (
        <SidebarMenuItemWithSub
          to="/product"
          icon="office-bag"
          title={intl.formatMessage({
            id: "MENU.PRODUCT-MANAGEMENT",
          })}
          fontIcon="bi-layers"
        >
          <SidebarMenuItem
            to="product/list"
            title={intl.formatMessage({
              id: "MENU.PRODUCT-MANAGEMENT.LIST",
            })}
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>
      )}

      {canViewCategoriesManagement && (
        <SidebarMenuItemWithSub
          to="/categories"
          icon="category"
          title={intl.formatMessage({
            id: "MENU.CATEGORIES-MANAGEMENT",
          })}
          fontIcon="bi-layers"
        >
          <SidebarMenuItem
            to="categories/list"
            title={intl.formatMessage({
              id: "MENU.CATEGORIES-MANAGEMENT.LIST",
            })}
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>
      )}

      {canViewWithdrawRequest && (
        <SidebarMenuItemWithSub
          to="/withdraw"
          icon="credit-cart"
          title={intl.formatMessage({
            id: "MENU.WITHDRAW-MANAGEMENT",
          })}
          fontIcon="bi-layers"
        >
          <SidebarMenuItem
            to="withdraw/list"
            title={intl.formatMessage({
              id: "MENU.WITHDRAW.LIST",
            })}
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>
      )}

      {canViewPurchaseHistoryRequest && (
        <SidebarMenuItemWithSub
          to="/purchase-history"
          icon="basket-ok"
          title={intl.formatMessage({
            id: "MENU.PURCHASE-MANAGEMENT",
          })}
          fontIcon="bi-layers"
        >
          <SidebarMenuItem
            to="purchase-history/list"
            title={intl.formatMessage({
              id: "MENU.PURCHASE_HISTORY.LIST",
            })}
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>
      )}

      {hasPermission(PERMISSIONS_MAP.LIST_RANKING) && (
        <SidebarMenuItem
          to="/ranking"
          icon="ranking"
          title={intl.formatMessage({ id: "RANKING" })}
          fontIcon="bi-app-indicator"
        />
      )}
      {canViewAlarmManagement && (
        <SidebarMenuItemWithSub
          to="/alarm-management"
          icon="notification"
          title={intl.formatMessage({ id: "MENU.ALARM-MANAGEMENT" })}
          fontIcon="bi-app-indicator"
        >
          {hasPermission(PERMISSIONS_MAP.LIST_PUSH) && (
            <SidebarMenuItem
              to="alarm-management/push-management"
              title={intl.formatMessage({ id: "MENU.PUSH-MANAGEMENT" })}
              hasBullet={true}
            />
          )}
          {/* {hasPermission(PERMISSIONS_MAP.LIST_EVENT) && (
            <SidebarMenuItem
              to="alarm-management/event"
              title={intl.formatMessage({ id: "MENU.EVENT" })}
              hasBullet={true}
            />
          )}
          {hasPermission(PERMISSIONS_MAP.LIST_NOTICE) && (
            <SidebarMenuItem
              to="alarm-management/notice-management"
              title={intl.formatMessage({ id: "NOTICE" })}
              hasBullet={true}
            />
          )} */}
          {hasPermission(PERMISSIONS_MAP.NOTIFICATION_LOG) && (
            <SidebarMenuItem
              to="alarm-management/notification-log"
              title={intl.formatMessage({ id: "MENU.NOTIFICATION-LOG" })}
              hasBullet={true}
            />
          )}
        </SidebarMenuItemWithSub>
      )}

      {hasPermission(PERMISSIONS_MAP.LIST_CS) && (
        <SidebarMenuItem
          to="/cs"
          icon="message-question"
          title={intl.formatMessage({ id: "CS" })}
          fontIcon="bi-app-indicator"
        />
      )}

      {/* {hasPermission(PERMISSIONS_MAP.LIST_RANKING_SETTING) && ( */}

      {canViewSettingManagement && (
        <SidebarMenuItemWithSub
          to="/setting-management"
          title={intl.formatMessage({ id: "MENU.SETTING-MANAGEMENT" })}
          fontIcon="bi-chat-left"
          icon="setting-4"
        >
          {/* {hasPermission(PERMISSIONS_MAP.LIST_RANKING_SETTING) && ( */}
          {/* <SidebarMenuItem
            to="/setting-management/rank-setting"
            title={intl.formatMessage({ id: "MENU.RANKING-SETTING" })}
            hasBullet={true}
          /> */}
          {/* )}
          {hasPermission(PERMISSIONS_MAP.LIST_AD_SETTING) && ( */}
          {/* <SidebarMenuItem
            to="/setting-management/company-info"
            title={intl.formatMessage({ id: "MENU.COMPANY_INFO" })}
            hasBullet={true}
          /> */}
          {/* )}
          {hasPermission(PERMISSIONS_MAP.LIST_APP_SETTING) && ( */}
          <SidebarMenuItem
            to="/setting-management/app-setting"
            title={intl.formatMessage({ id: "MENU.APP-SETTING" })}
            hasBullet={true}
          />
          {/* )}
          {hasPermission(PERMISSIONS_MAP.LIST_TERM) && ( */}
          <SidebarMenuItem
            to="/setting-management/policy-setting"
            title={intl.formatMessage({ id: "MENU.POLICY-SETTING" })}
            hasBullet={true}
          />

          <SidebarMenuItem
            to="/setting-management/term-setting"
            title={intl.formatMessage({ id: "MENU.TERM-SETTING" })}
            hasBullet={true}
          />

          {/* )}

          {hasPermission(PERMISSIONS_MAP.LIST_LOCALIZATION_SETTING) && ( */}
          {/* <SidebarMenuItem
            to="/setting-management/localization-setting"
            title={intl.formatMessage({ id: "MENU.LOCALIZATION-SETTING" })}
            hasBullet={true}
          /> */}
          {/* )} */}

          {/* <SidebarMenuItem
            to="/setting-management/feed-setting"
            title={intl.formatMessage({ id: "MENU.FEED-SETTING" })}
            hasBullet={true}
          /> */}
        </SidebarMenuItemWithSub>
      )}

      {canViewFeedDisplaySimulationManagement && (
        <SidebarMenuItemWithSub
          to="/simulation"
          icon="basket-ok"
          title={intl.formatMessage({
            id: "FEED-DISPLAY-SIMULATION",
          })}
          fontIcon="bi-layers"
        >
          {hasPermission(PERMISSIONS_MAP.DISPLAY_CONFIGURATION) && (
            <SidebarMenuItem
              to="simulation/config"
              title={intl.formatMessage({
                id: "DISPLAY-CONFIG",
              })}
              hasBullet={true}
            />
          )}
          {hasPermission(PERMISSIONS_MAP.DISPLAY_SIMULATION) && (
            <SidebarMenuItem
              to="simulation/display"
              title={intl.formatMessage({
                id: "DISPLAY-SIMULATION",
              })}
              hasBullet={true}
            />
          )}
          {hasPermission(PERMISSIONS_MAP.VIEW_CONTENT_LIST) && (
            <SidebarMenuItem
              to="simulation/list"
              title={intl.formatMessage({
                id: "CONTENT-LIST",
              })}
              hasBullet={true}
            />
          )}
        </SidebarMenuItemWithSub>
      )}

      {canViewFeedDisplaySettingManagement && (
        <SidebarMenuItemWithSub
          to="/feed-display-setting"
          icon="setting"
          title={intl.formatMessage({
            id: "FEED-DISPLAY-SETTING",
          })}
          fontIcon="bi-layers"
        >
          {hasPermission(PERMISSIONS_MAP.FEED_SETTING) && (
            <SidebarMenuItem
              to="feed-display-setting/config"
              title={intl.formatMessage({
                id: "FEED-SETTING",
              })}
              hasBullet={true}
            />
          )}
          {hasPermission(PERMISSIONS_MAP.FEED_DISPLAY) && (
            <SidebarMenuItem
              to="feed-display-setting/display"
              title={intl.formatMessage({
                id: "FEED-DISPLAY",
              })}
              hasBullet={true}
            />
          )}
        </SidebarMenuItemWithSub>
      )}

      {canViewAuditionEventManagement && (
        <SidebarMenuItemWithSub
          to="/audition"
          icon="office-bag"
          title={intl.formatMessage({
            id: "MENU.AUDITION-EVENT",
          })}
          fontIcon="bi-layers"
        >
          {hasPermission(PERMISSIONS_MAP.AUDITION_DASHBOARD) && (
            <SidebarMenuItem
              to="audition/dashboard-management"
              title={intl.formatMessage({
                id: "MENU.AUDITION-DASHBOARD",
              })}
              hasBullet={true}
            />
          )}
          {hasPermission(PERMISSIONS_MAP.AUDITION_PURCHASE_STATUS) && (
            <SidebarMenuItem
              to="audition/purchase-management"
              title={intl.formatMessage({
                id: "MENU.AUDITION-PURCHASE-STATUS",
              })}
              hasBullet={true}
            />
          )}
        </SidebarMenuItemWithSub>
      )}

      <SidebarMenuItem
        to="/event-page-management"
        icon="joystick"
        title={intl.formatMessage({ id: "EVENT-MANAGEMENT" })}
        fontIcon="bi-app-indicator"
      />
    </>
  );
};

export { SidebarMenuMain };
