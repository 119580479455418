/* eslint-disable jsx-a11y/anchor-is-valid */
import { useLang } from "_metronic/i18n/Metronici18n";
import { DatePicker, Input, Select, Tabs, TabsProps } from "antd";
import en from "antd/es/date-picker/locale/en_US";
import ko from "antd/es/date-picker/locale/ko_KR";
import { NOTIFICATION_DEVICE } from "app/constants";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

dayjs.extend(customParseFormat);

const Step1 = ({ data, setFormData, error, updateError }: any) => {
  const intl = useIntl();
  const lang = useLang();

  // const defaultForm = {
  //   title: [] as any,
  //   thumbnail: null,
  //   thumbnail_fold: null,
  //   start_date: "",
  //   end_date: "",
  //   link: "",
  // };

  // const [formData, setFormData] = useState(defaultForm);

  const formData = data;

  useEffect(() => {
    setFormData(formData);
  }, [formData]);

  // useEffect(() => {
  //   return () => {
  //     setFormData(defaultForm);
  //     updateError(null);
  //   };
  // }, []);

  const isEdit = data;

  return (
    <>
      <div
        className="d-flex flex-column flex-sm-row current row"
        data-kt-stepper-element="content"
      >
        <div className="mb-10">
          <div className="fv-row mb-10">
            <span className="fs-7 text-gray-700 fw-bold pe-4 ps-1 d-none d-md-block required mb-2">
              {intl.formatMessage({ id: "TITLE" })}:{" "}
            </span>
            <input
              className="form-control form-control-lg form-control-solid"
              name="title"
              placeholder={intl.formatMessage({ id: "TYPE-IN-HERE" })}
              value={formData?.title}
              onChange={(e) =>
                setFormData((preState: any) => {
                  return { ...preState, title: e.target.value };
                })
              }
            />
          </div>

          <div className="d-flex flex-column flex-lg-row gap-4">
            <div style={{ width: "100%" }}>
              <span className="fs-7 text-gray-700 fw-bold pe-4 ps-1 d-none d-md-block required mb-2">
                {intl.formatMessage({ id: "EVENT-START-AT" })}:{" "}
              </span>
              <DatePicker
                locale={lang === "ko" ? ko : en}
                format={"YYYY-MM-DD HH:mm"}
                value={formData?.start_date ? dayjs(formData.start_date) : null}
                showTime={{ format: "HH:mm" }}
                onChange={(data, date) => {
                  setFormData((preState: any) => {
                    console.log("hello");
                    return { ...preState, start_date: date };
                  });
                }}
                status={error?.start_date ? "error" : ""}
              />
            </div>

            <div style={{ width: "100%" }}>
              <span className="fs-7 text-gray-700 fw-bold pe-4 ps-1 d-none d-md-block required mb-2">
                {intl.formatMessage({ id: "EVENT-END-AT" })}:{" "}
              </span>
              <DatePicker
                locale={lang === "ko" ? ko : en}
                format={"YYYY-MM-DD HH:mm"}
                value={formData?.end_date ? dayjs(formData.end_date) : null}
                showTime={{ format: "HH:mm" }}
                disabledDate={(date) =>
                  date && date.valueOf() <= dayjs(formData.start_date).valueOf()
                }
                onChange={(data, date) => {
                  console.log(date);

                  setFormData((preState: any) => {
                    console.log("cbd");
                    return { ...preState, end_date: date };
                  });
                }}
                status={error?.end_date ? "error" : ""}
              />
            </div>
          </div>
        </div>
        <div className="d-flex gap-10 mb-10">
          <div style={{ width: 200 }}>
            <span className="fs-7 text-gray-700 fw-bold pe-4 ps-1 d-none d-md-block required mb-2">
              {intl.formatMessage({ id: "PHONE-THUMBNAIL" })}:
            </span>
            <div className="upload-btn-wrapper">
              {formData?.thumbnail ? (
                <div style={{ width: 200, height: 150 }}>
                  <img
                    style={{
                      objectFit: "cover",
                      height: "100%",
                      width: "100%",
                    }}
                    src={
                      isEdit &&
                      formData?.thumbnail?.toString()?.includes("https")
                        ? formData?.thumbnail
                        : window.URL.createObjectURL(formData?.thumbnail)
                    }
                  />
                </div>
              ) : (
                <button className="btn">Upload</button>
              )}

              <input
                id="image"
                name="image"
                type="file"
                accept="image/*"
                onChange={(event) => {
                  if (event?.currentTarget?.files?.[0]) {
                    setFormData((preState: any) => {
                      return {
                        ...preState,
                        thumbnail: event?.currentTarget?.files?.[0],
                      };
                    });
                  }
                }}
              />
            </div>
          </div>
          <div style={{ width: 200 }}>
            <span className="fs-7 text-gray-700 fw-bold pe-4 ps-1 d-none d-md-block required mb-2">
              {intl.formatMessage({ id: "FOLD-THUMBNAIL" })}:
            </span>
            <div className="upload-btn-wrapper">
              {formData?.thumbnail_fold ? (
                <div style={{ width: 200, height: 150 }}>
                  <img
                    style={{
                      objectFit: "cover",
                      height: "100%",
                      width: "100%",
                    }}
                    src={
                      isEdit &&
                      formData?.thumbnail_fold?.toString()?.includes("https")
                        ? formData?.thumbnail_fold
                        : window.URL.createObjectURL(formData?.thumbnail_fold)
                    }
                  />
                </div>
              ) : (
                <button className="btn">Upload</button>
              )}

              <input
                id="background"
                name="background"
                type="file"
                accept="image/*"
                onChange={(event) => {
                  if (event?.currentTarget?.files?.[0]) {
                    setFormData((preState: any) => {
                      return {
                        ...preState,
                        thumbnail_fold: event?.currentTarget?.files?.[0],
                      };
                    });
                  }
                }}
              />
            </div>
          </div>
        </div>

        <div className="fv-row mb-10">
          <span className="fs-7 text-gray-700 fw-bold pe-4 ps-1 d-none d-md-block required mb-2">
            {intl.formatMessage({ id: "LINK" })}:{" "}
          </span>
          <input
            className="form-control form-control-lg form-control-solid"
            name="link"
            placeholder={intl.formatMessage({ id: "TYPE-IN-HERE" })}
            value={formData?.link}
            onChange={(e) =>
              setFormData((preState: any) => {
                return { ...preState, link: e.target.value };
              })
            }
          />
        </div>
      </div>
    </>
  );
};

export { Step1 };
