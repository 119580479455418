import { applyDefaultQueryConditions, linkPagination } from "app/utils";
import { callApiWithTryCatch } from "app/utils/apiUntils";

import { Response } from "_metronic/helpers";
import axios, { AxiosResponse } from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const GET_LIST_EVENT_URL = `${API_URL}/campaigns`;

export const getPaginationOnGoingEvent = async (query: any) => {
  const newquery = applyDefaultQueryConditions(query);

  if (!newquery.order_by) {
    newquery.sort_by = "start_date";
    newquery.order_by = "DESC";
  }

  const res = await callApiWithTryCatch(
    async () => await axios.get(`${GET_LIST_EVENT_URL}`, { params: newquery })
  );
  const { list, meta } = res.data;

  return {
    data: list as Array<any>,
    pagination: {
      page: newquery?.page,
      items_per_page: newquery?.limit,
      links: linkPagination(newquery?.page, meta?.total, newquery?.limit),
      total: meta?.total,
      total_happening: meta?.total_happening,
      total_ended: meta?.total_ended,
    },
  };
};

const create_event = (data: any) => {
  return axios.post(`${GET_LIST_EVENT_URL}`, data);
};

const edit_event = (id: any, data: any) => {
  return axios.put(`${GET_LIST_EVENT_URL}/${id}`, data);
};

const delete_event = (id: any) => {
  return axios.delete(`${GET_LIST_EVENT_URL}/${id}`);
};

export { create_event, edit_event, delete_event };
