// @ts-nocheck
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { KTIcon, toAbsoluteUrl } from "_metronic/helpers";
import Fancybox from "app/_components/Fancybox";
import clsx from "clsx";
import { useIntl } from "react-intl";
import { Column } from "react-table";

import { Tag, Popconfirm } from "antd";
import { useQueryRequest } from "app/_components/coreListData/core/QueryRequestProvider";
import { useQueryResponse } from "app/_components/coreListData/core/QueryResponseProvider";
import DateTimeCell from "app/_components/table/cells/DateTimeCell";
import { UserCustomHeader } from "app/_components/table/columns/UserCustomHeader";
import { UsersList } from "app/_modules/user-management/pages/users-list";
import { randomAntdColor } from "app/utils";
import * as React from "react";
import { delete_event } from "../core/_requests";
import { CreateAppModal } from "../components/ModalCreateEvent";
import { useState } from "react";
import { EVENT_STATUS_OPTIONS_2 } from "app/constants";

const usersColumns: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="STATUS"
        className="min-w-50px text-start"
        useQueryRequest={useQueryRequest}
      />
    ),

    id: "status",
    Cell: ({ ...props }) => {
      const intl = useIntl();
      const row = props.row.original;
      return intl.formatMessage({
        id: row?.status == 1 ? "ACTIVE" : "INACTIVE",
      });
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="EVENT-STATUS"
        className="min-w-50px text-start"
        useQueryRequest={useQueryRequest}
      />
    ),

    id: "event_status",
    Cell: ({ ...props }) => {
      const intl = useIntl();
      const row = props.row.original;
      return intl.formatMessage({
        id:
          EVENT_STATUS_OPTIONS_2?.find(
            (i: any) => i?.value == row?.event_status
          )?.label || row?.event_status,
      });
    },
  },

  /* {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="DISPLAY-INDEX"
        className="min-w-50px text-start"
        useQueryRequest={useQueryRequest}
      />
    ),

    id: "display_index",
    accessor: "display_index",
  }, */
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="PHONE-THUMBNAIL"
        className="min-w-100px text-center"
        useQueryRequest={useQueryRequest}
      />
    ),

    id: "thumbnail",
    Cell: ({ ...props }) => {
      const row = props.row.original;
      return <RenderEventImg images={[row.thumbnail]} />;
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="FOLD-THUMBNAIL"
        className="min-w-100px text-center"
        useQueryRequest={useQueryRequest}
      />
    ),

    id: "thumbnail_fold",
    Cell: ({ ...props }) => {
      const row = props.row.original;
      return <RenderEventImg images={[row.thumbnail_fold]} />;
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="TITLE"
        className="min-w-50px text-start"
        useQueryRequest={useQueryRequest}
      />
    ),

    id: "title",
    accessor: "title",
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="LINK"
        className="min-w-50px text-start"
        useQueryRequest={useQueryRequest}
      />
    ),

    id: "link",
    Cell: ({ ...props }) => {
      const link = props?.row?.original?.link;
      return (
        <a href={link} target="_blank">
          URL
        </a>
      );
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="START-AT"
        className="min-w-100px"
        useQueryRequest={useQueryRequest}
        isNoSort={false}
        isCenter={true}
      />
    ),
    id: "start_date",
    accessor: "start_date",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const date = props?.row?.original.start_date;
      return <DateTimeCell date={date} />;
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="END-AT"
        className="min-w-100x"
        useQueryRequest={useQueryRequest}
        isNoSort={false}
        isCenter={true}
      />
    ),
    id: "end_date",
    accessor: "end_date",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const date = props?.row?.original.end_date;
      return <DateTimeCell date={date} />;
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="CREATED-AT"
        className="min-w-100px"
        useQueryRequest={useQueryRequest}
        isNoSort={false}
        isCenter={true}
        isForceDesc={true}
      />
    ),
    id: "created_at",
    accessor: "created_at",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const date = props?.row?.original.created_at;
      return <DateTimeCell date={date} />;
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="UPDATED-AT"
        className="min-w-100px"
        useQueryRequest={useQueryRequest}
        isNoSort={false}
        isCenter={true}
      />
    ),
    id: "updated_at",
    accessor: "updated_at",
    isCenterRow: true,
    Cell: ({ ...props }) => {
      const date = props?.row?.original.updated_at;
      return <DateTimeCell date={date} />;
    },
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="ACTION"
        useQueryRequest={useQueryRequest}
        isCenter={true}
      />
    ),
    id: "a",
    isCenterRow: true,
    Cell: ({ ...props }) => (
      <div className="d-flex" style={{ gap: 5 }}>
        <EditAdminModal row={props?.row?.original} />
        <DeleteComponent row={props?.row?.original} />
      </div>
    ),
  },
];

function EditAdminModal({ row }) {
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false);

  const handleOnClose = () => {
    setShowCreateAppModal(false);
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          type="button"
          className="btn btn-sm btn-icon btn-bg-light btn-active-light-primary "
          onClick={() => setShowCreateAppModal(true)}
        >
          <KTIcon iconName="notepad-edit" className="fs-2" />
        </button>
      </div>

      {showCreateAppModal && (
        <CreateAppModal
          show={showCreateAppModal}
          handleClose={handleOnClose}
          info={row}
        />
      )}
    </>
  );
}

function DeleteComponent({ row }) {
  const { refetch } = useQueryResponse();
  const intl = useIntl();

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Popconfirm
        title={intl.formatMessage({ id: "DELETE" })}
        description={intl.formatMessage({ id: "DELETE-CONFIRM" })}
        onConfirm={async () => {
          const res = await delete_event(row.id);
          if (res.status == 200) refetch();
        }}
        onCancel={() => {}}
        okText={intl.formatMessage({ id: "YES" })}
        cancelText={intl.formatMessage({ id: "NO" })}
        placement="topRight"
      >
        <button className="btn btn-sm btn-icon btn-bg-light btn-active-light-primary">
          <KTIcon iconName="trash" className="fs-2" />
        </button>
      </Popconfirm>
    </div>
  );
}

const RenderEventImg = ({ images }) => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      {/* begin:: Avatar */}
      <Fancybox
        options={{
          Carousel: {
            infinite: true,
          },
        }}
        key={Math.random()}
      >
        <div style={{ display: "inline-block", maxWidth: 70 }}>
          <div className="post_img">
            {images ? (
              <div className="symbol-label overflow-hidden">
                <a data-fancybox="gallery" href={images} key={Math.random()}>
                  <img src={images} style={{ maxHeight: 70, maxWidth: 70 }} />
                </a>
              </div>
            ) : (
              <div style={{ display: "none" }}>
                <a data-fancybox="gallery" href={url} key={Math.random()}>
                  <img src={url} width="70" height="70" />
                </a>
              </div>
            )}
          </div>
        </div>
      </Fancybox>
    </div>
  );
};

export { usersColumns };
