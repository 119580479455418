/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { KTIcon } from "_metronic/helpers";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { createPortal } from "react-dom";
// import { create_admin, edit_admin } from "../_requests";
import { Box, CircularProgress, Typography } from "@mui/material";
import { notification } from "antd";
import { useIntl } from "react-intl";

import { create_event, edit_event } from "../core/_requests";
import { Step1 } from "./Step1";
import {
  uploadCloudFrame,
  uploadImage,
} from "app/_modules/content-management/pages/announce/core/_requests";
import { useQueryResponse } from "app/_components/coreListData/core/QueryResponseProvider";

type Props = {
  show: boolean;
  handleClose: () => void;
  triggerListNotify: () => void;
};

const modalsRoot = document.getElementById("root-modals") || document.body;

const CreateAppModal = ({ show, handleClose, info }: any) => {
  console.log(info);
  const intl = useIntl();
  const { refetch } = useQueryResponse();

  const [data, setData] = useState<any>();
  const [dataOptions, setDataOptions] = useState<any>();
  const [error, setError] = useState(null);
  const [isLoadingAddNewEvent, setIsLoadingAddNewEvent] = useState(false);
  const isEdit = info;

  useEffect(() => {
    setData({
      title: info?.title,
      start_date: info?.start_date,
      end_date: info?.end_date,
      thumbnail: info?.thumbnail,
      thumbnail_fold: info?.thumbnail_fold,
      link: info?.link,
    });
  }, []);

  const updateData = (fieldsToUpdate: any) => {
    setData(fieldsToUpdate);
  };

  const handleOnCloseModal = () => {
    handleClose();
  };

  const updateError = (value: any) => {
    setError(value);
  };

  const upload = async (image: any) => {
    if (typeof image == "string" && image?.includes("https")) return image;
    try {
      const image_buffer = await image?.arrayBuffer();
      console.log(image_buffer);
      const res = await uploadImage({
        list: [
          {
            filename: `default/event/${image?.name}`,
          },
        ],
      });

      const upload = await uploadCloudFrame(
        res?.data?.data?.[0]?.url,
        image_buffer,
        {
          "Content-Type": image?.type,
        }
      );
      if (upload?.status === 200) {
        return res?.data?.data?.[0]?.filename;
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSubmitAddEvent = async () => {
    setIsLoadingAddNewEvent(true);

    try {
      const thumbnailURL = await upload(data?.thumbnail);
      const thumbnailFoldURL = await upload(data?.thumbnail_fold);

      const res = isEdit
        ? await edit_event(info?.id, {
            ...data,
            thumbnail: thumbnailURL,
            thumbnail_fold: thumbnailFoldURL,
          })
        : await create_event({
            ...data,
            thumbnail: thumbnailURL,
            thumbnail_fold: thumbnailFoldURL,
          });
      if (res.status === 201) {
        setIsLoadingAddNewEvent(false);
        handleClose();
        refetch();
        notification.success({
          message: intl.formatMessage({
            id: "CREATE.ANNOUNCE-SUCCESS",
          }),
        });
      }

      if (res.status === 200) {
        setIsLoadingAddNewEvent(false);
        handleClose();
        refetch();
        notification.success({
          message: intl.formatMessage({
            id: "UPDATE.ANNOUNCE-SUCCESS",
          }),
        });
      }
    } catch (error) {
      setIsLoadingAddNewEvent(false);
      notification.error({
        message: intl.formatMessage({
          id: "SYSTEM-ERROR",
        }),
      });
    }
  };

  console.log(data);

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-700px"
      show={show}
      onHide={handleOnCloseModal}
      backdrop={true}
    >
      <div className="modal-header">
        <h2>{intl.formatMessage({ id: "ADD-NEW-EVENT" })}</h2>
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={handleClose}
        >
          <KTIcon className="fs-1" iconName="cross" />
        </div>
        {/* end::Close */}
      </div>

      <div className="modal-body py-lg-10 px-lg-10">
        {/*begin::Stepper */}
        <div
          className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row "
          id="kt_modal_create_app_stepper"
        >
          {/*begin::Content */}
          <div className="px-lg-5 w-full">
            {/*begin::Form */}
            <form noValidate id="kt_modal_create_app_form">
              <Step1
                data={data}
                error={error}
                setFormData={updateData}
                info={info}
                dataOptions={dataOptions}
                isLoading={false}
                triggerListTypeNotify={() => {}}
                updateError={updateError}
              />

              {/*begin::Actions */}
              <div className="d-flex flex-stack pt-10">
                <div className="me-2"></div>
                <div>
                  <button
                    type="button"
                    className="btn btn-lg btn-primary min-w-150px"
                    data-kt-stepper-action="next"
                    // disabled={isValidated()}
                    onClick={handleSubmitAddEvent}
                  >
                    {isLoadingAddNewEvent ? (
                      <CircularProgress size={16} />
                    ) : (
                      intl.formatMessage({ id: "ADD-EVENT" })
                    )}
                  </button>
                </div>
              </div>
              {/*end::Actions */}
            </form>
            {/*end::Form */}
          </div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>
    </Modal>,
    modalsRoot
  );
};

export { CreateAppModal };
