import axios from "axios";
import { applyDefaultQueryConditions, linkPagination } from "app/utils/index";
import { callApiWithTryCatch } from "app/utils/apiUntils";

const API_URL = process.env.REACT_APP_API_URL;
const GET_ANNOUNCE_URL = `${API_URL}/events`;
const CREATE_PATH_URL = `${API_URL}/upload/presigned`;
const uninterceptedAxiosInstance = axios.create();

const create_announce = (data: any) => {
  return axios.post(`${GET_ANNOUNCE_URL}`, data);
};

const edit_announce = (id: any, data: any) => {
  return axios.put(`${GET_ANNOUNCE_URL}/${id}`, data);
};

const delete_announce = (id: any) => {
  return axios.delete(`${GET_ANNOUNCE_URL}/${id}`);
};

const uploadImage = (data: any) => {
  return axios.post(CREATE_PATH_URL, data);
};

const uploadCloudFrame = (url: string, data: any, newHeader: any) => {
  return uninterceptedAxiosInstance.put(url, data, {
    headers: {
      ...newHeader,
    },
  });
};

export const getPaginationPosts = async (query: any) => {
  const newquery = applyDefaultQueryConditions(query);
  const res = await callApiWithTryCatch(
    async () => await axios.get(`${GET_ANNOUNCE_URL}`, { params: newquery })
  );
  if (!res || (res && res.statusCode !== 200)) {
  }
  const { list, meta, user_platform, summary } = res.data;
  return {
    data: list as Array<any>,
    pagination: {
      page: newquery?.page,
      items_per_page: newquery?.limit,
      links: linkPagination(newquery?.page, meta.total, newquery?.limit),
      total: meta.total,
      summary,
    },
    user_platform: user_platform,
  };
};

export {
  create_announce,
  delete_announce,
  uploadImage,
  uploadCloudFrame,
  edit_announce,
};
